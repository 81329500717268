import axios from 'axios';
import * as Constants from "../config/config.js";
import * as Globals from "../config/GLOBALS.js";

const token = "SSp@LHp*$bp6kL";
axios.defaults.headers.common['Authorization'] = `Bearer SSp@LHp*$bp6kL`;
axios.defaults.headers.common['x-access-token'] = `SSp@LHp*$bp6kL`;
const headers_post= {
              headers: {
              'Authorization': `Basic ${token}`
              }
              }
const sleep = (waitTimeInMs) => new Promise(resolve => setTimeout(resolve, waitTimeInMs));

    async function submit(data) {

      var response=""
      try {
             response = await axios.post(`https://`+Constants.URL+`:8443/api/aitrvl/submit`, data,headers_post);
             console.log(response)
          } catch (error) {
                console.error(error);
                console.log("lets try 3 times to resubmit")
                await sleep(3000)
                try {
                  response = await axios.post(`https://`+Constants.URL+`:8443/api/aitrvl/submit`, data,headers_post);
                    console.log(response)
                } catch (error) {
                  console.error(error);
                  await sleep(3000)
                  try {
                    response = await axios.post(`https://`+Constants.URL+`:8443/api/aitrvl/submit`, data,headers_post);
                      console.log(response)
                  } catch (error) {
                    console.error(error);
                    await sleep(3000)
                    try {
                      response = await axios.post(`https://`+Constants.URL+`:8443/api/aitrvl/submit`, data,headers_post);
                        console.log(response)
                    } catch (error) {
                        console.error(error);
                      }




                }



              }
          }


      try {
          //  console.log(data);
          //  console.log("DUDU");
            //const response1 = await axios.get(`https://alertair.net:8443/`, data);
            //console.log(response1);
           response = await axios.post(`https://govis.ai:2087/api/aitrvl/submit`, data,headers_post);


          } catch (error) {
                console.error(error);
              }


      return response;





      }


      async function submitFinishLater(data) {

      try {
            //console.log(data);
            //const response1 = await axios.get(`https://alertair.net:8443/`, data);
            //console.log(response1);
            const response = await axios.post(`https://govis.ai:2053/api/govisai/submitFinishLater`, data,headers_post);


          } catch (error) {
                console.error(error);
              }
        }


        async function submitDeliveryIssue(data) {

        try {
              //console.log(data);
              //const response1 = await axios.get(`https://alertair.net:8443/`, data);
              //console.log(response1);
              const response = await axios.post(`https://govis.ai:2053/api/govisai/submitDeliveryIssue`, data,headers_post);
              return response;

            } catch (error) {
                  console.error(error);
                }
          }

        async function checkRecaptcha(data) {

        try {
              //console.log(data);
              //const response1 = await axios.get(`https://alertair.net:8443/`, data);
              //console.log(response1);
              const response = await axios.post(`https://govis.ai:2053/api/govisai/checkRecaptcha`, data,headers_post);
              return response;

            } catch (error) {
                  console.error(error);
                }
          }

      async function confirm_payment(data) {

        var response=""
        try {
            //console.log(data);
            //const response1 = await axios.get(`https://alertair.net:8443/`, data);
            //console.log(response1);
             response = await axios.post(`https://`+Constants.URL+`:8443/api/aitrvl/confirm`, data,headers_post);


          } catch (error) {
                console.error(error);
              }


              try {
                  //  console.log(data);
                  //  console.log("DUDU");
                    //const response1 = await axios.get(`https://alertair.net:8443/`, data);
                    //console.log(response1);
                   response = await axios.post(`https://govis.ai:2087/api/aitrvl/confirm`, data,headers_post);


                  } catch (error) {
                        console.error(error);
                      }


        }


export {submit,confirm_payment,submitFinishLater,checkRecaptcha,submitDeliveryIssue};
