import React, { lazy, Suspense, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import SpeedIcon from '@mui/icons-material/Speed';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Card, Grid, CssBaseline, Container, Toolbar, Typography,Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CardHeader from '@mui/material/CardHeader';
import PublicIcon from '@mui/icons-material/Public';
import CardMedia from '@mui/material/CardMedia';
import Alert from '@mui/material/Alert';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Fab from '@mui/material/Fab';
import {useFloating} from '@floating-ui/react';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ApprovalIcon from '@mui/icons-material/Approval';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import ClipLoader from "react-spinners/ClipLoader";
import * as Globals from "./config/GLOBALS.js";
import CardActions from '@mui/material/CardActions';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import jwt_decode from "jwt-decode";
import "../styles.css";
import ResponsiveAppBar from "./Header.js";
import Footer from "./Footer.js";
import ErrorBoundaries from "./error-boundaries.jsx";
import {submit} from "./api/Functions.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import actions from "./actions.js";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import * as Constants from "./config/config.js";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import i18n from "./i18n.ts";
import { useTranslation } from "react-i18next";



const Dashboard = lazy(() =>  import("./Dashboard.js"));
const Confirmation = lazy(() => import("./confirmation.jsx"));
const Webhook = lazy(() => import("./webhook.jsx"));
const FlightSearch = lazy(() => import("./search/flight-search.jsx"));
const FlightBooking = lazy(() => import("./booking/flight-booking.jsx"));

const Home = () => {
  const [user, setUser] = useState({});
  const [mainPage, setMainPage] = useState(false);
  const [continueGuided, setContinueGuided] = useState(false);
  const [continueNotGuided, setContinueNotGuided] = useState(false);
  const [confirmPage, setConfirmPage] = useState(false);
  const serviceTypeInfo = useSelector((state) => state.flightSearch.serviceTypeInfo);
  const priceInfo = useSelector((state) => state.flightSearch.priceInfo);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const applyNowButton = useSelector((state) => state.flightSearch.applyNowButton);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();



  function handleSignOut(event) {
    setUser({});
    document.getElementById("signInDiv").hidden = false;
  }

    const handleClose = () => {
      setContinueNotGuided(false);


    };


  const delay = async (ms) => {
        return new Promise((resolve) =>
            setTimeout(resolve, ms));
    };

    const handleClick = async () => {
      setIsLoading(true);
        await delay(1000);
         setIsLoading(false)
         setContinueGuided(true)

         guided.scrollIntoView({ behavior: "smooth" })


    };
  useEffect(() => {
    /* global google */


    if (!Globals.GUIDED.includes(Constants.PLATFORM))
      setContinueGuided(true)
    //alert(window.location);
    if (window.location==`https://${Globals.SERVER_URL}/` || window.location==`https://www.${Globals.SERVER_URL}/`)
    {
        setMainPage(true);
    }


    if (window.location.pathname=="/confirmation/")
       setConfirmPage(true);



      dispatch({
          type: actions.SET_APPLY_NOW_BUTTON,
          data: true,

        });
    /*google.accounts.id.initialize({
      client_id:
        "387074555543-bsr5194lli1qr2mlpuvrdu2si7islvm5.apps.googleusercontent.com",
      callback: handleCallbackResponse
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large"
    });*/

    //google.accounts.id.prompt();
    //
    //console.log(Object.entries(user).length);
    //console.log("DUDU");
  }, []);

  return (

    <div className="root">


      <CssBaseline />

        <BrowserRouter>

        <ResponsiveAppBar />
        {true && <Box component="span" >

          {Globals.SERVER=="GOVIS.AI" && <img
          width="80px"
          align="left"
          className="logo-govis"
          display="inline-block"
          src={`https://${Globals.SERVER_URL}/images/GOVIS-WHITE.png`}
          alt={`Govis.ai`}
          loading="lazy"

          onClick={() =>window.location.reload()}
          />}



          </Box>}

        <Toolbar />


        {false && <FloatingWhatsApp
        chatMessage={!mainPage? t('welcome') + Constants.PLATFORM + t('online_form'): t('welcome_form')}
        phoneNumber="19173100929"
        accountName={'Emma'}
        statusMessage="24/7 Customer Support"

        />}




        <Container>


        {mainPage && <Box  sx={{ mx:5, mb:1 }}>
            <Typography
              variant="h4"
              sx={{
                mt:3,
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: 'black',
                textDecoration: 'none',
              }}
            >

           <Typography
                variant="h4"
                sx={{
                  mt:1,
                  fontWeight: 700,
                  letterSpacing: '.1rem',
                  color: 'black',
                  textDecoration: 'none',
                }}
              >{'Entry Online Form - Expedited' } </Typography><SpeedIcon/>

              <b id="Home"/>
            <br/>
            </Typography>
        </Box>}


       {mainPage && <Grid container >
       <Grid  >
       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://vietnam.${Globals.SERVER_URL}`} >
         <CardHeader
             title={`Vietnam`}
             subheader="Apply Online"
           />
           <CardContent>
                <Typography sx={{fontSize:150 }}>
                <span className="fi fi-vn"></span><br/>
              </Typography>
           </CardContent>
        </Card>
       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://canada.${Globals.SERVER_URL}`} >
          <CardHeader
              title={"Canada"}
              subheader="Apply Online"
            />
            <CardContent>
                <Typography sx={{fontSize:150 }}>
                 <span className="fi fi-ca"></span><br/>
               </Typography>
            </CardContent>
         </Card>
         <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://ethiopia.${Globals.SERVER_URL}`} >
           <CardHeader
               title={"Ethiopia"}
               subheader="Apply Online"
             />
             <CardContent>
                  <Typography sx={{fontSize:150 }}>
                  <span className="fi fi-et"></span><br/>
                </Typography>
             </CardContent>
          </Card>

       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://dubai.${Globals.SERVER_URL}`} >
            <CardHeader
                title={"UAE"}
                subheader="Apply Online"
              />
              <CardContent>
                  <Typography sx={{fontSize:150 }}>
                   <span className="fi fi-ae"></span><br/>
                 </Typography>
              </CardContent>
           </Card>
       <Card sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://india.${Globals.SERVER_URL}`} >
             <CardHeader
                 title={"India"}
                 subheader="Apply Online"
               />
               <CardContent>
                  <Typography sx={{fontSize:150 }}>
                    <span className="fi fi-in"></span><br/>

                  </Typography>
               </CardContent>
            </Card>

          <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://morocco.${Globals.SERVER_URL}`} >
             <CardHeader

                 title={"Morocco"}
                 subheader="Apply Online"
               />
               <CardContent>
                  <Typography sx={{fontSize:150 }}>
                    <span className="fi fi-ma"></span><br/>

                  </Typography>
               </CardContent>
            </Card>

            <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://us.${Globals.SERVER_URL}`} >
               <CardHeader

                   title={"United States"}
                   subheader="Apply Online"
                 />
                 <CardContent>
                    <Typography sx={{fontSize:150 }}>
                      <span className="fi fi-us"></span><br/>

                    </Typography>
                 </CardContent>
              </Card>

              <Card  sx={{mx:4}} className="maincard" onClick={() =>window.location.href = `https://srilanka.${Globals.SERVER_URL}`} >
                 <CardHeader

                     title={"Sri Lanka"}
                     subheader="Apply Online"
                   />
                   <CardContent>
                      <Typography sx={{fontSize:150 }}>
                        <span className="fi fi-lk"></span><br/>

                      </Typography>
                   </CardContent>
                </Card>


           </Grid>
          </Grid>}

        { !mainPage && <Box   sx={{ mx:5, mb:1 }}>
            <Typography
              variant="h1"
              sx={{

                mt:3,
                fontWeight: 700,
                letterSpacing: '.1rem',
                color: 'black',
                textDecoration: 'none',
                textTransform: 'uppercase'
              }}
            >


            {!Constants.OVERRIDE_LOGO && <span className={Constants.FLAG_CODE}></span>}
            {Constants.OVERRIDE_LOGO &&  <img
              width="80px"

              align="center"

              //display="inline-block"
              src={`https://${Globals.SERVER_URL}/${Constants.PLATFORM.toLowerCase()}/logo.jpg`}
              alt={`Govis.ai`}
            loading="lazy"
              />}




            <br/>

            {/* Constants.PLATFORM=="India" ?' Start Your Indian Journey: Get Started with Our Free Prequalification Form! Let\'s Begin!' : (Constants.PLATFORM +' Entry Online Form')*/}

            {Constants.LANG=="he" && (Constants.TITLE!="" ? Constants.TITLE:Constants.PLATFORM) }
            {Constants.LANG=="en" && !Globals.GUIDED.includes(Constants.PLATFORM) && (Constants.TITLE!="" ? Constants.TITLE:Constants.PLATFORM) }


            {Constants.LANG=="en" && ( Globals.GUIDED.includes("Us")? t('us_guide'):"" )}
            {Constants.LANG=="en" && ( Globals.GUIDED.includes("Canada") ? t('canada_guide'):"")}


            {Constants.LANG=="en"  && !Constants.PLATFORM=='Us'&& !Constants.PLATFORM=='Canada' && ( Constants.PLATFORM=='Dubai'? 'UAE':Constants.PLATFORM )}<br/></Typography>


            { (Constants.PLATFORM=="Dubai" || Constants.PLATFORM=="Ethiopia" || Constants.PLATFORM=="Us-il" || Constants.PLATFORM=="Morocco" || Constants.PLATFORM=="Morocco-il" || Constants.PLATFORM=="India"  )? (<div><Typography
                variant="h5"

                sx={{
                  mt:1,
                  fontWeight: 300,
                  letterSpacing: '.1rem',
                  color: 'black',
                  fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{t('form_online_expedited') } </Typography> {/*<img
               width="40px"
               center
               src={`https://${Globals.SERVER_URL}/images/siren.png`}
               alt={`Expedited`}
             loading="lazy"
               />*/}{/*<Typography
                  variant="h6"

                  sx={{
                    mt:1,
                    fontWeight: 800,
                    letterSpacing: '.1rem',
                    color: 'black',
                      fontSize: '0.7rem',
                    textDecoration: 'none',
                       textTransform: 'uppercase'
                  }}
                >{'Designed for Rapid Action' } </Typography>*/} </div>):
                Constants.PLATFORM=="Payfine"? t('pay_traffic_fines'):
                Constants.PLATFORM=="Us"?

                <Typography
                variant="h5"

                sx={{

                  mt:1,
                  fontWeight: 300,
                  letterSpacing: '.1rem',
                  color: 'black',
                    fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{  t('online_esta')  } </Typography>








                :

                Constants.PLATFORM=="Canada"?

                <Typography
                variant="h5"

                sx={{

                  mt:1,
                  fontWeight: 300,
                  letterSpacing: '.1rem',
                  color: 'black',
                    fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{  t('online_eta')  } </Typography>

              :





                 (<div>

                <Typography
                variant="h5"

                sx={{

                  mt:1,
                  fontWeight: 300,
                  letterSpacing: '.1rem',
                  color: 'black',
                    fontSize: '1.3rem',
                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >{t('entry_online_expedited') } </Typography>
              {/*<img
               width="40px"
               center
               src={`https://${Globals.SERVER_URL}/images/siren.png`}
               alt={`Money-back guarantee`}
             loading="lazy"
               />*/} {/*<Typography
                  variant="h6"

                  sx={{
                    mt:1,
                    fontWeight: 800,
                    letterSpacing: '.1rem',
                    color: 'black',
                    fontSize: '0.7rem',
                    textDecoration: 'none',
                       textTransform: 'uppercase'
                  }}
                >{'Designed for Rapid Action' } </Typography>*/}</div>  )}
              { false&& <Typography
                variant="caption"

                sx={{

                  mt:1,
                  fontWeight: 100,
                  letterSpacing: '.1rem',
                  color: 'black',

                  textDecoration: 'none',
                     textTransform: 'uppercase'
                }}
              >  {t('twenty_four_seven')} </Typography>}

              <b id="Home"/>
              <b id="בית"/>



        </Box>}
        {false && <Box component="span" >

          {Globals.SERVER=="GOVIS.AI" && <img
          width="80px"

          align="center"

          //display="inline-block"
          src={`https://${Globals.SERVER_URL}/images/GOVIS.png`}
          alt={`Govis.ai`}
        loading="lazy"
          />}



          </Box>}
        <Grid item xs={12} sm={12} sx={{mb:3}}>

        {!mainPage  && !confirmPage && <Box component="span" >
              <AccessTimeIcon />
            <Typography
              variant="subtitle1"


              sx={{

                fontWeight: 200,
                color: 'black',
                textDecoration: 'none',
              }}
                  >
              &nbsp;
              {t('one_minute')}
              </Typography>

          </Box>}


      </Grid>
      {/*<Alert severity="warning">{`Tail based alerts are enabled for premium subscribed customers only! this time we let you try it for free`}</Alert>*/}

      <Grid id="guided" item xs={12} sm={12} sx={{mr:5,ml:5,mb:3}}>
      { (Globals.GUIDED.includes(Constants.PLATFORM) || Constants.PLATFORM=='Payfine') && !mainPage  && !confirmPage && <Box   component="span" sx={{ pl:1,pr:1,pb:1, pt:1,color:"#fbf4d6!important", border: '1px sold grey' }}>
          <Typography
            variant="caption"


            sx={{

              fontWeight: 300,
              fontSize: 12,
              color: 'black',
              textDecoration: 'none',
            }}
                >
            &nbsp;
              {/*<Alert severity="info">  </Alert>*/}
              {Constants.PLATFORM=='Us'? t('disclaimer_top'):(Constants.PLATFORM=='Canada'? t('disclaimer_top_canada'):(Constants.PLATFORM=='Payfine'? t('disclaimer_top_payfine'):"")) }

            </Typography>
        </Box>}
    </Grid>
        <Grid item xs={12} sm={12} sx={{mb:3}}>
        {!mainPage  && !confirmPage && <Box id="entry" component="span" sx={{ pl:1,pr:1,pb:1, pt:1,backgroundColor: "#fbf4d6!important",color:"#fbf4d6!important", border: '1px sold grey' }}>
            <Typography
              variant="caption"


              sx={{

                fontWeight: 300,

                color: 'black',
                textDecoration: 'none',
              }}
                  >
              &nbsp;
              {t('important_note')}
              </Typography>
          </Box>}
      </Grid>

      <Grid item xs={12} sm={12} sx={{mb:3}}>
      {  continueGuided && !mainPage && !confirmPage && <Box component="span" sx={{ pl:1,pr:1,pb:1, pt:1,color:"#fbf4d6!important", border: '1px sold grey' }}>
          <Typography
            variant="caption"
              display="inline"

            sx={{

              fontWeight: 700,

              color: 'black',
              textDecoration: 'none',
            }}
                >
            &nbsp;
            {Constants.PLATFORM=="Payfine" ? t('reveal_pricing1_payfine'):t('reveal_pricing1')}
            </Typography>
            <Typography
              variant="caption"
              display="inline"


              sx={{

                fontWeight: 700,
                color: 'black',
                textDecoration: 'none',
              }}
                  >
              &nbsp;

              {Constants.PLATFORM=="Payfine" ? t('reveal_pricing2_payfine'):t('reveal_pricing2')}
              </Typography>
        </Box>}
    </Grid>

    <Grid item xs={12} sm={12} sx={{mr:5,ml:5,mb:3}}>
    {   Globals.GUIDED.includes(Constants.PLATFORM) && continueGuided && !mainPage && !confirmPage && <Box component="span" sx={{ pl:1,pr:1,pb:1, pt:1,color:"#fbf4d6!important", border: '1px sold grey' }}>
        <Typography
          variant="caption"
            display="inline"

          sx={{

            fontWeight: 250,
            fontSize: 12,
            color: 'black',
            textDecoration: 'none',
          }}
              >
          &nbsp;

            {/*<Alert severity="warning">  {t('privacy_matter')}</Alert>*/}
             {t('privacy_matter')}
          </Typography>

      </Box>}
  </Grid>

        <Grid
            container
            styles={{ marginTop: 100 }}
          >
          <Grid item xs={12} sm={12}>
              <ErrorBoundaries>
                <Suspense fallback={<div>{t('loading')}</div>}>
                <ClipLoader
                    color={"#000000"}
                    loading={isLoading}
                  //  cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />

                    { !continueGuided &&   <br/>}


                { Globals.GUIDED.includes(Constants.PLATFORM) && !mainPage && !continueGuided && <Button  sx={{mr:5,mt:2, fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"#24A0ED"}}  variant="contained"

                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {

                  handleClick()

                }}

                > {t('Continue Guided')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}

                <Dialog
                //   fullScreen={fullScreen}
                   open={continueNotGuided}
                   onClose={handleClose}
                   aria-labelledby="responsive-dialog-title"
                 >
                   <DialogTitle id="responsive-dialog-title">
                     {"Important Message!"}
                   </DialogTitle>
                   <DialogContent>
                     <DialogContentText>

                        {Constants.LANG=="en" && ( Constants.PLATFORM=='Us'? t('dialog_guided_us'):"" )}
                        {Constants.LANG=="en" && ( Constants.PLATFORM=='Canada'? t('dialog_guided_canada'):"" )}

                     </DialogContentText>
                   </DialogContent>
                   <DialogActions>
                     {/*<Button autoFocus onClick={handleClose}>
                       Disagree
                     </Button>*/}
                     {Globals.GUIDED.includes(Constants.PLATFORM) && !continueGuided && <Button sx={{mr:5,mt:2,mb:2, fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"#24A0ED"}}  variant="contained"

                     onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                       setContinueNotGuided(false)
                       handleClick()

                     }}

                     > {t('Continue Guided')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}
                   </DialogActions>
                 </Dialog>
                {Globals.GUIDED.includes(Constants.PLATFORM) && !mainPage &&  !continueGuided && <Button sx={{mt:2,fontSize:"20px",fontColor:"red", color:"white", backgroundColor:"black"}}  variant="contained"

                onClick={(event: React.ChangeEvent<HTMLInputElement>) => {


                  setContinueNotGuided(true)
                  //handleClick()

                }}

                > {t('Not Guided')} {Constants.LANG=="he"? <ArrowBackIcon/>:<ArrowForwardIcon/>}</Button>}
                  { !continueGuided &&   <br/>}

                { applyNowButton.result && !priceInfo.result && !mainPage && <button class="floating-button"   onClick={(event: React.ChangeEvent<HTMLInputElement>) => {
                    guided.scrollIntoView({ behavior: "smooth" })

                    handleClick()

                  }}>{t('apply_now_get_expert')}</button>}
                  <Routes>


                    {continueGuided && !mainPage&& <Route
                      exact={true}
                      path={`/`}
                      element={<FlightSearch/>}
                    /> }
                    { !mainPage&&<Route
                      exact={true}
                      path={`/confirmation`}
                      element={<Confirmation/>}
                    />}
                    { 0 && <Route
                      exact={true}
                      path={`/webhook`}
                      element={<Webhook/>}
                    />}
                  </Routes>
                </Suspense>
              </ErrorBoundaries>
              <Footer mainPage={mainPage}/>
            </Grid>
          </Grid>
        </Container>
        </BrowserRouter>
      <br />

    </div>

  );
};

export default Home;
