export const PLATFORM="Vietnam"
export const URL="visetnam.net"
export const FLAG_CODE="fi fi-vn"
export const OVERRIDE_LOGO=false
//export const MODE="TEST"
export const MODE="PRODUCTION"
export const WHATSAPP="NO"
export const TITLE="Vietnam"
export const LANG="en"
export const SHOW_PRICE="random"
export const WHICH_STRIPE="random"
