import * as Constants from "./config.js";
function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

var randomBool = (function() {
  var a = new Uint8Array(1);
  return function() {
    crypto.getRandomValues(a);
    return a[0] > 127;
  };
})();



export const STRIPE=true
export const PAYPAL=true
export const EMERGENCY_COST=50
let result=""
if (Constants.SHOW_PRICE=="random")
  result=randomBool()
else
  result=Constants.SHOW_PRICE

export const REVEAL_PRICE=result
//export const REVEAL_PRICE=false
//export const REVEAL_PRICE=randomBool()
//export const REVEAL_PRICE=false
export const SHOW_COST=false
export const SHOW_COST_PAYMENT_ONLY=false
export const SHOW_TBD=true
export const SITE_KEY="6Ldk3jgpAAAAADWFJRXNB1liCa7-CcvcyaPC4Cmp"
//export const SERVER="GOVISA.AI"
//export const SERVER_URL="govisa.ai"
export const SERVER="GOVIS.AI"
export const SERVER_URL="govis.ai"
export const GUIDED=[]
//ZAATARIUS
var STRIPE1="pk_live_51Jv0dsKtGhJNikuOSlUnTmLr6KqY6ca3NZaod2Zivz9i8qlnQkchQIPszxnJZV9RFbatGoqogCK9TuvTY42xeavF00hQbxyyIz"
var STRIPE1_PORT=8443
//TZEDEK TECH
var STRIPE2="pk_live_51PqLFdE4jCfuypqKfrxfq1JEwY03dshrDAJ9l5ljfh2c6r1ggmFdJgLbrLVEYQopzFSlXh4Y5QBnQgKAbJ4MwP5C00ddWHmN5W"
var STRIPE2_PORT=8444

let which_stripe=""
if (Constants.WHICH_STRIPE=="random")
  which_stripe=randomIntFromInterval(1, 2);
else
  which_stripe=Constants.WHICH_STRIPE

var STRIPE_CLIENT_=""
var STRIPE_PORT_=""
var SERVER_IP_=""


///OVERRIDE////
//which_stripe=randomIntFromInterval(1, 2);
which_stripe=2

if (which_stripe==1)
{
   STRIPE_CLIENT_=STRIPE1
   STRIPE_PORT_=STRIPE1_PORT
   SERVER_IP_=SERVER_URL
}
else if (which_stripe==2)
{
   STRIPE_CLIENT_=STRIPE2
   STRIPE_PORT_=STRIPE2_PORT
   SERVER_IP_="govisa.ai"
}
export const STRIPE_CLIENT=STRIPE_CLIENT_
export const STRIPE_PORT=STRIPE_PORT_
export const SERVER_IP=SERVER_IP_

export const STRIPE_TEST_KEY="pk_test_51PqLFdE4jCfuypqKyxLsLatrg9FhBhk4ir3qjVfantiZxefyGd6uJDIOQwadvYeW7ECHxdoDlkpFggxrqHsxfjVj00dxgYBReT"

export const TEST_ALLOW_IP="2a01:cb00:f:7500:a921:2e2b:88a:1b3a"

//export const SERVER_URL="aitrvl.com"
//export const SERVER="AI.TRVL"
